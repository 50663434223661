body {
  font-family: "Montserrat", sans-serif !important;
}
.footer {
  background: #272727;
}

.slide-up {
  overflow: hidden;
}
.slide-up > div {
  transform: translateY(-100%);
  transition: 0.4s ease-in-out;
}
#vertical-tabpanel-2,
#vertical-tabpanel-1 {
  width: 100%;
}
.MuiBox-root {
  padding: 0px !important;
}
.modal-ui {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 20px 30px;
}
#vertical-tabpanel-2,
#vertical-tabpanel-1,
#vertical-tabpanel-3,
#vertical-tabpanel-0 {
  width: 100%;
}
.MuiButtonBase-root {
  justify-content: flex-start !important;
}
.red:after {
  content: "*";
  font-weight: bold;
  color: #ff0000;
  margin-left: 4px;
}
.MuiTabs-indicator {
  display: none;
}
.tabs-ui .Mui-selected {
  background-color: #41b14d;
  border-radius: 100px;
  color: #fff !important;
  justify-content: center !important;
  align-items: center !important;
}
.tabs-ui .MuiButtonBase-root {
  justify-content: center !important;
  align-items: center !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 500px;
}
.tabs-ui .Mui-selected:hover {
  /* background-color: blueviolet; */
  opacity: 0.8;
}
.tabs-ui .MuiButtonBase-root:hover {
  font-weight: 700;
  font-size: 17px;
}
/* .my-pag .MuiButtonBase-root-MuiPaginationItem-root {
  padding: 0px 20px !important;
  padding: 20px;
} */
.my-pag .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  padding: 0px 10px !important;
}
.MuiInputBase-root {
  height: 42px;
}
